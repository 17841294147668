.client-data-root {
  padding: 46px 20px;
  position: relative;
  background-color: #f5f5f8;

  &__form {
    margin-top: 40px;

    fieldset {
      border: none;
    }

    input {
      border: none;
      outline: none;
      color: #304858;
    }

    label {
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      font-family: Montserrat;
      color: #304858;
    }
  }

  &__field {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border: none;
    border-radius: 15px !important;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 18px;
    color: #304858;
    margin-bottom: 18px;

    .select-form .MuiOutlinedInput-root {
      border-radius: 15px;
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }

    .MuiInputBase-root {
      font-size: inherit;
      font-family: Montserrat;
    }
  }
}

@media screen and (min-width: 570px) {
 .client-data-root {
   padding: 46px 33px;
 }
}